/* eslint-disable react/jsx-indent */
import React from 'react';
import CondensedActions from './CondensedActions';
import CondensedNav from './CondensedNav';
import useStateContext from '../../hooks/useStateContext';

const CondensedPanel = () => {
	const { menuOpen } = useStateContext();
    return (
		<div className={`condensed-panel ${menuOpen ? 'condensed-panel--active' : ''}`}>
		  <div className="condensed-panel__wrapper wrapper wrapper--xs">
		    <CondensedNav />
		    <CondensedActions />
		  </div>
		</div>
		
    );
};

export default CondensedPanel;
