/* eslint-disable react/jsx-indent */
import React from 'react';
import Hamburger from './Hamburger';
import PrimaryNav from './PrimaryNav';

const Header = () => {
    return (
		<header className="header header--sticky" role="banner">
		  <Hamburger />
		  <PrimaryNav />
		</header>
		
    );
};

export default Header;
