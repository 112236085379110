/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link } from 'gatsby';
import useMenu from '../../hooks/useMenu';
import useStateContext from '../../hooks/useStateContext';

const CondensedNav = () => {
	const items = useMenu();
	const { setMenuOpen } = useStateContext();
	const toggle = () => setMenuOpen(false);
    return (
		<nav className="condensed-nav">
		<ul className="condensed-nav__items">
			{items.map(item => {
				return (
				<li key={item.guid} className="condensed-nav__item">
					<Link onClick={toggle} activeClassName="current" partiallyActive to={item.slug} className="condensed-nav__link">
					<span className="condensed-nav__text">{item.title}</span>
					</Link>
				</li>
				)
			})}
		</ul>
		</nav>
		
    );
};

export default CondensedNav;
