/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link } from 'gatsby';

const FooterLogo = () => {
    return (
		<div className="footer-logo">
		<h2 className="footer-logo__title">
			<Link to="/" className="footer-logo__link">
			Lissa Carlino, Author
			</Link>
		</h2>
		</div>
    );
};

export default FooterLogo;