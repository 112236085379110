/* eslint-disable react/jsx-indent */
import React from 'react';
import FooterLogo from './FooterLogo';
import SocialNav from './SocialNav';

const Footer = () => {
    return (
		<footer className="footer" role="contentinfo">
		  <div className="wrapper">
		    <div className="footer__inner">
		      <FooterLogo />
		      <SocialNav />
		    </div>
		  </div>
		</footer>
		
    );
};

export default Footer;
