/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link } from 'gatsby';
import { TwitterIcon, FacebookIcon, EnvelopeIcon } from './Icons';

const SocialNav = () => {
    return (
		<nav className="social-nav">
		  <ul className="social-nav__items">
		    <li className="social-nav__item social-nav__item--twitter">
		      <a href="https://twitter.com/ewallercarlino" target="_blank" rel="noopener noreferrer" className="social-nav__link">
		        <span className="social-nav__text hide">Twitter</span>
				<TwitterIcon className="social-nav__icon" />
		      </a>
		    </li>
		    <li className="social-nav__item social-nav__item--facebook">
		      <a href="https://facebook.com/knackeredtruth" target="_blank" rel="noopener noreferrer" className="social-nav__link">
		        <span className="social-nav__text hide">Facebook</span>
				<FacebookIcon className="social-nav__icon" />
		      </a>
		    </li>
		  </ul>
		</nav>
		
    );
};

export default SocialNav;
