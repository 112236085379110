/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-indent */
import React from 'react';
import icons from '../../icons/icons.svg';

export const EnvelopeIcon = (props) => (
    <svg {...props}>
        <use xlinkHref={`${icons}#envelope`} />
    </svg>
);

export const FacebookIcon = (props) => (
    <svg {...props}>
        <use href={`${icons}#facebook`} />
    </svg>
);

export const TwitterIcon = (props) => (
    <svg {...props}>
        <use xlinkHref={`${icons}#twitter`} />
    </svg>
);

export const PinterestIcon = (props) => (
    <svg {...props}>
        <use xlinkHref={`${icons}#pinterest`} />
    </svg>
);


