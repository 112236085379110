/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link } from 'gatsby';
import useMenu from '../../hooks/useMenu';

const PrimaryNav = () => {
	const items = useMenu();
    return (
		<div className="primary-nav-wrap">
		  <div className="logo">
		    <h1 className="logo__title">
		      <Link to="/" className="logo__link">
		        Lissa Carlino
		      </Link>
		    </h1>
		  </div>
		  <nav className="primary-nav">
		    <ul className="primary-nav__items">
		      {items.map(item => (
		      <li key={item.guid} className="primary-nav__item">
		        <Link activeClassName="current" partiallyActive to={item.slug} className="primary-nav__link {props.LinkingMode}">
		          <span className="primary-nav__text">
		            {item.title}
		          </span>
		        </Link>
		      </li>
			  ))}
		    </ul>
		  </nav>
		  <div className="primary-nav-break" />
		  <div className="cta">
		    <Link to="/order/" className="cta__link btn btn--sm btn--ghost">Buy the Book</Link>
		  </div>
		</div>
		
    );
};

export default PrimaryNav;
