/* eslint-disable react/jsx-indent */
import React, { useCallback } from 'react';
import useStateContext from '../../hooks/useStateContext';

const Hamburger = () => {
	const { menuOpen, setMenuOpen } = useStateContext();
	const toggle = useCallback((e) => {
		e.preventDefault();
		setMenuOpen(!menuOpen);
	}, [menuOpen]);
    return (
		<button onClick={toggle} type="button" className={`hamburger ${menuOpen ? 'hamburger--active' : ''}`}>
		  <div className="hamburger__line" />
		  <span className="hide">Menu</span>
		</button>
		
    );
};

export default Hamburger;
