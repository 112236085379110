/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link } from 'gatsby';

const CondensedActions = () => {
    return (
		<nav className="condensed-actions">
			<ul className="condensed-actions__items">
			<li className="condensed-actions__item">
				<Link to="/order/" className="condensed-actions__link btn btn--primary">Buy the Book</Link>
			</li>
			{/*
			<li className="condensed-actions__item">
				<Link to="/subscribe/" className="condensed-actions__link btn btn--ghost">Subscribe</Link>
			</li>*/}
			</ul>
		</nav>
    );
};

export default CondensedActions;
